var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "card card-custom gutter-b bg-white border-0" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "card-body", attrs: { id: "printableTable" } },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6 col-12" }, [
                  _vm.gallary_detail_path.length > 0
                    ? _c("div", { staticClass: "slide-v" }, [
                        _c("div", { staticClass: "slide" }, [
                          _c("img", {
                            staticStyle: { width: "80%" },
                            attrs: {
                              src: _vm.gallary_detail_path[
                                _vm.currentSelectedImg
                              ],
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleImageSelect(
                                  _vm.currentSelectedImg
                                )
                              },
                            },
                          }),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm._l(
                        _vm.gallary_detail_path,
                        function (gallary_detail, index) {
                          return [
                            gallary_detail != ""
                              ? _c("div", { staticClass: "col-2 float-left" }, [
                                  _c("img", {
                                    staticClass: "img-thumbnail",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { src: gallary_detail, alt: "..." },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleImageSelect(index)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  index != _vm.currentSelectedImg
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-block btn-danger btn-xs py-0",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeImage(index)
                                            },
                                          },
                                        },
                                        [_vm._v("-")]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary cta",
                          attrs: { "data-toggle": "pill", href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleImageSelect()
                            },
                          },
                        },
                        [_vm._v("Subir archivo")]
                      ),
                      _vm._v(" "),
                      _c("hr"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-md-6 col-12" },
                  [
                    _c("label", [_vm._v("Categorías")]),
                    _vm._v(" "),
                    _c("multiselect", {
                      attrs: {
                        options: _vm.categories,
                        "custom-label": _vm.nameWithLang,
                        placeholder: "Seleccione",
                        label: "name",
                        "track-by": "id",
                        multiple: true,
                        taggable: true,
                      },
                      on: { input: _vm.setCat, remove: _vm.removeCat },
                      model: {
                        value: _vm.category_id,
                        callback: function ($$v) {
                          _vm.category_id = $$v
                        },
                        expression: "category_id",
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.has("category_id")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("category_id")),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        staticClass: "nav nav-pills lang-tab",
                        attrs: { id: "pills-tab1", role: "tablist" },
                      },
                      _vm._l(_vm.languages, function (language) {
                        return _c(
                          "li",
                          {
                            staticClass: "nav-item",
                            on: {
                              click: function ($event) {
                                return _vm.setSelectedLanguage(language.id)
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "nav-link btn-light shadow-none rounded-0",
                                class:
                                  language.id == _vm.selectedLanguage
                                    ? "show active"
                                    : "",
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(language.language_name) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          {
                            staticClass: "tab-content lang-content",
                            attrs: { id: "v-pills-tabContent" },
                          },
                          _vm._l(_vm.languages, function (language, index) {
                            return _c(
                              "div",
                              {
                                staticClass: "tab-pane fade",
                                class:
                                  language.id == _vm.selectedLanguage
                                    ? "show active"
                                    : "",
                              },
                              [
                                _c("h6", { staticClass: "text-body" }, [
                                  _vm._v(
                                    "\n                    Nombre del Producto ( " +
                                      _vm._s(language.language_name) +
                                      " )\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "fieldset",
                                  { staticClass: "form-group mb-3" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.title[language.id],
                                          expression: "title[language.id]",
                                        },
                                      ],
                                      staticClass:
                                        "form-control bg-transparent text-dark",
                                      attrs: {
                                        type: "text",
                                        name: "name" + index,
                                        id: "basicInput",
                                        placeholder: "Nombre",
                                      },
                                      domProps: {
                                        value: _vm.title[language.id],
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.title,
                                              language.id,
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.setTitle(
                                              $event.target.value,
                                              language.id
                                            )
                                          },
                                        ],
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.errors.has("title")
                                      ? _c("small", {
                                          staticClass: "form-text text-danger",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.errors.get("title")
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.errors.has("title." + index)
                                      ? _c("small", {
                                          staticClass: "form-text text-danger",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.errors.get("title." + index)
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("h6", { staticClass: "text-body" }, [
                                  _vm._v(
                                    "\n                    Descripción ( " +
                                      _vm._s(language.language_name) +
                                      " )\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "fieldset",
                                  { staticClass: "form-group mb-3" },
                                  [
                                    _c("textarea", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.desc[language.id],
                                          expression: "desc[language.id]",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      staticStyle: { height: "130px" },
                                      attrs: {
                                        id: "label-textarea",
                                        name: "desc" + index,
                                        rows: "6",
                                        name: "notes",
                                        placeholder: "Descripción",
                                        spellcheck: "false",
                                      },
                                      domProps: {
                                        value: _vm.desc[language.id],
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.desc,
                                              language.id,
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.setDesc(
                                              $event.target.value,
                                              language.id
                                            )
                                          },
                                        ],
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.errors.has("desc")
                                      ? _c("small", {
                                          staticClass: "form-text text-danger",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.errors.get("desc")
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.errors.has("desc." + index)
                                      ? _c("small", {
                                          staticClass: "form-text text-danger",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.errors.get("desc." + index)
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-12 col-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "text-dark" }, [
                            _vm._v("URL para vídeo"),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.video_url,
                                expression: "video_url",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text" },
                            domProps: { value: _vm.video_url },
                            on: {
                              input: [
                                function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.video_url = $event.target.value
                                },
                                function ($event) {
                                  return _vm.setVideoUrl($event.target.value)
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _vm.errors.has("video_url")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.errors.get("video_url")
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 d-flex justify-content-end" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-primary cta",
              class: { active: _vm.isActive("ad-info-tab") },
              attrs: { "data-toggle": "pill", href: "#ad-info" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setActive("ad-info-tab")
                },
              },
            },
            [_vm._v("Continuar")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal },
        on: {
          toggleImageSelect: _vm.toggleImageSelect,
          setImage: _vm.setImage,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "card-header border-0 align-items-center" },
      [
        _c(
          "h3",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [_vm._v("\n        Info Basica\n      ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }