var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Home Estilo")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.appgeneral.home_style,
                expression: "appgeneral.home_style",
              },
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.appgeneral,
                  "home_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { attrs: { value: "style 1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 2" } }, [_vm._v("Style 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 3" } }, [_vm._v("Style 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 4" } }, [_vm._v("Style 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 5" } }, [_vm._v("Style 5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 6" } }, [_vm._v("Style 6")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 7" } }, [_vm._v("Style 7")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 8" } }, [_vm._v("Style 8")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 9" } }, [_vm._v("Style 9")]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Card Estilo")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.appgeneral.card_style,
                expression: "appgeneral.card_style",
              },
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.appgeneral,
                  "card_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          _vm._l(28, function (index) {
            return _c(
              "option",
              { key: index, domProps: { value: "style " + index } },
              [
                _vm._v(
                  "\r\n                    Style " +
                    _vm._s(index) +
                    "\r\n                "
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Banner Estilo")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.appgeneral.banner_style,
                expression: "appgeneral.banner_style",
              },
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.appgeneral,
                  "banner_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { attrs: { value: "style 1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 2" } }, [_vm._v("Style 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 3" } }, [_vm._v("Style 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 4" } }, [_vm._v("Style 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 5" } }, [_vm._v("Style 5")]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Categoría Estilo")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.appgeneral.category_style,
                expression: "appgeneral.category_style",
              },
            ],
            staticClass:
              "js-example-basic-single js-states form-control bg-transparent",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.appgeneral,
                  "category_style",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { attrs: { value: "style 1" } }, [_vm._v("Style 1")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 2" } }, [_vm._v("Style 2")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 3" } }, [_vm._v("Style 3")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 4" } }, [_vm._v("Style 4")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 5" } }, [_vm._v("Style 5")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "style 6" } }, [_vm._v("Style 6")]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("Nombre de la APP")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.appgeneral.app_name,
              expression: "appgeneral.app_name",
            },
          ],
          ref: "app_name",
          staticClass: "form-control border-dark",
          attrs: { type: "email", placeholder: "" },
          domProps: { value: _vm.appgeneral.app_name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.appgeneral, "app_name", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-6" }, [
      _c("label", [_vm._v("IOS Url")]),
      _vm._v(" "),
      _c("fieldset", { staticClass: "form-group mb-3" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.appgeneral.ios_app_url,
              expression: "appgeneral.ios_app_url",
            },
          ],
          ref: "ios_app_url",
          staticClass: "form-control border-dark",
          attrs: { type: "email", placeholder: "" },
          domProps: { value: _vm.appgeneral.ios_app_url },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.appgeneral, "ios_app_url", $event.target.value)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-12" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "submit" },
          on: {
            click: function ($event) {
              return _vm.updateSetting()
            },
          },
        },
        [_vm._v("Enviar")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }