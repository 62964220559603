var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "\n                  card card-custom\n                  gutter-b\n                  bg-transparent\n                  shadow-none\n                  border-0\n                ",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "\n                    card-header\n                    align-items-center\n                    border-bottom-dark\n                    px-0\n                  ",
                      },
                      [
                        _vm._m(0),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "icons d-flex" },
                          [
                            _vm.$parent.permissions.includes("product-manage")
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "btn ml-2 p-0 kt_notes_panel_toggle",
                                    attrs: {
                                      to: "/admin/add-product",
                                      "data-toggle": "tooltip",
                                      title: "",
                                      "data-placement": "right",
                                      "data-original-title":
                                        "Check out more demos",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "\n                          bg-secondary\n                          h-30px\n                          font-size-h5\n                          w-30px\n                          d-flex\n                          align-items-center\n                          justify-content-center\n                          rounded-circle\n                          shadow-sm\n                        ",
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-plus white",
                                            attrs: {
                                              width: "25px",
                                              height: "25px",
                                              viewBox: "0 0 16 16",
                                              fill: "currentColor",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d: "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  {
                    staticClass: "card card-custom gutter-b bg-white border-0",
                  },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "table-responsive",
                            attrs: { id: "printableTable" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "dataTables_wrapper no-footer",
                                attrs: { id: "product_wrapper" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_length",
                                    attrs: { id: "product_length" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        "Mostrar\n                            "
                                      ),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.limit,
                                              expression: "limit",
                                            },
                                          ],
                                          attrs: { name: "product_length" },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.limit = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function ($event) {
                                                return _vm.fetchproducts()
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { attrs: { value: "10" } },
                                            [_vm._v("10")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "25" } },
                                            [_vm._v("25")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "50" } },
                                            [_vm._v("50")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "100" } },
                                            [_vm._v("100")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "200" } },
                                            [_vm._v("200")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "500" } },
                                            [_vm._v("500")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            { attrs: { value: "1000" } },
                                            [_vm._v("1000")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                            entradas"
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dataTables_filter",
                                    attrs: { id: "product_filter" },
                                  },
                                  [
                                    _c("label", [
                                      _vm._v("Search:"),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchParameter,
                                            expression: "searchParameter",
                                          },
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: "",
                                        },
                                        domProps: {
                                          value: _vm.searchParameter,
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.fetchproducts()
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.searchParameter =
                                              $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    this.searchParameter != ""
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-light  font-weight-bold mr-1 mb-1",
                                            staticStyle: {
                                              padding:
                                                "0.375rem 0.75rem !important",
                                            },
                                            on: { click: _vm.clearSearch },
                                          },
                                          [_vm._v(" Clear ")]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticClass: "display dataTable no-footer",
                                    attrs: { id: "product", product: "grid" },
                                  },
                                  [
                                    _c("thead", { staticClass: "text-body" }, [
                                      _c("tr", { attrs: { product: "row" } }, [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "id"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "id"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "31.25px" },
                                            attrs: {
                                              tabindex: "0",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label": "ID: ordenar",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sorting("id")
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                ID\n                              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "title"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "title"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "31.25px" },
                                            attrs: {
                                              tabindex: "0",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sorting("title")
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Nombre\n                              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass:
                                              "no-sort sorting_disabled",
                                            staticStyle: { width: "95.5288px" },
                                            attrs: {
                                              tabindex: "0",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-label":
                                                "product: activate to sort column ascending",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Imagen\n                              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy ==
                                                "category_name"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy ==
                                                    "category_name"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "31.25px" },
                                            attrs: {
                                              tabindex: "0",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sorting(
                                                  "category_name"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Categoría\n                              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy ==
                                                "product_type"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy ==
                                                    "product_type"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "31.25px" },
                                            attrs: {
                                              tabindex: "0",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sorting(
                                                  "product_type"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Tipo\n                              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "price"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "price"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "31.25px" },
                                            attrs: {
                                              tabindex: "0",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sorting("price")
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                PVP " +
                                                _vm._s(_vm.getCurrencyTitle()) +
                                                "\n                              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy == "price"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy == "price"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "31.25px" },
                                            attrs: {
                                              tabindex: "0",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sorting(
                                                  "discount_price"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Descuento " +
                                                _vm._s(_vm.getCurrencyTitle()) +
                                                "\n                              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "sorting",
                                            class:
                                              (this.$data.sortType == "asc" ||
                                                this.$data.sortType == "ASC") &&
                                              this.$data.sortBy ==
                                                "product_status"
                                                ? "sorting_asc"
                                                : (this.$data.sortType ==
                                                    "desc" ||
                                                    this.$data.sortType ==
                                                      "DESC") &&
                                                  this.$data.sortBy ==
                                                    "product_status"
                                                ? "sorting_desc"
                                                : "sorting",
                                            staticStyle: { width: "31.25px" },
                                            attrs: {
                                              tabindex: "0",
                                              rowspan: "1",
                                              colspan: "1",
                                              "aria-sort": "ascending",
                                              "aria-label":
                                                "ID: activate to sort column descending",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.sorting(
                                                  "product_status"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Status\n                              "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.$parent.permissions.includes(
                                          "product-manage"
                                        )
                                          ? _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "no-sort sorting_disabled",
                                                staticStyle: {
                                                  width: "53.1891px",
                                                },
                                                attrs: {
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-label": "Action",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                Acción\n                              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      {
                                        staticClass: "kt-table-tbody text-dark",
                                      },
                                      _vm._l(_vm.products, function (product) {
                                        return _c(
                                          "tr",
                                          {
                                            key: product.id,
                                            staticClass:
                                              "kt-table-row kt-table-row-level-0 odd",
                                            attrs: { product: "row" },
                                          },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "sorting_1" },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(product.product_id) +
                                                    "\n                              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    product.detail == null
                                                      ? ""
                                                      : product.detail[0]
                                                      ? product.detail[0].title
                                                      : ""
                                                  ) +
                                                  "\n                              "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _c("img", {
                                                staticStyle: { height: "80px" },
                                                attrs: {
                                                  src:
                                                    +product.product_gallary !=
                                                    null
                                                      ? "/gallary/" +
                                                        product.product_gallary
                                                          .gallary_name
                                                      : "",
                                                },
                                              }),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    product.category == null
                                                      ? ""
                                                      : !product.category[0]
                                                          .category_detail
                                                      ? ""
                                                      : product.category[0]
                                                          .category_detail
                                                          .detail[0]
                                                      ? product.category[0]
                                                          .category_detail
                                                          .detail[0].name
                                                      : ""
                                                  ) +
                                                  "\n                              "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(product.product_type) +
                                                  "\n                              "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    product.product_price
                                                  ) +
                                                  "\n                              "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    product.product_discount_price
                                                  ) +
                                                  "\n                              "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    product.product_status
                                                  ) +
                                                  "\n                              "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _vm.$parent.permissions.includes(
                                              "product-manage"
                                            )
                                              ? _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "click-edit",
                                                        attrs: {
                                                          to: {
                                                            path:
                                                              "/admin/product/" +
                                                              product.product_id,
                                                          },
                                                          id: "click-edit1",
                                                          "data-toggle":
                                                            "tooltip",
                                                          title: "",
                                                          "data-placement":
                                                            "right",
                                                          "data-original-title":
                                                            "Check out more demos",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.editproduct(
                                                              product
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-edit",
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteproduct(
                                                              product.product_id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-trash",
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "pagination pagination-sm m-0 float-right",
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.prev_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchproducts(
                                                  _vm.pagination.prev_page_url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Previo")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("li", { staticClass: "disabled" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "page-link text-dark",
                                          attrs: { href: "#" },
                                        },
                                        [
                                          _vm._v(
                                            "Page " +
                                              _vm._s(
                                                _vm.pagination.current_page
                                              ) +
                                              " of\n                              " +
                                              _vm._s(_vm.pagination.last_page)
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "page-item",
                                        class: [
                                          {
                                            disabled:
                                              !_vm.pagination.next_page_url,
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "page-link",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.fetchproducts(
                                                  _vm.pagination.next_page_url
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Proximo")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.display_form
      ? _c(
          "div",
          {
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel",
            class: _vm.display_form ? "offcanvas-on" : "",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "\n        offcanvas-header\n        d-flex\n        align-items-center\n        justify-content-between\n        pb-3\n      ",
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Agregar Producto"),
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "\n          btn btn-sm btn-icon btn-light btn-hover-primary\n          kt_notes_panel_close\n        ",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        _vm.display_form = 0
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d: "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("form", { attrs: { id: "myform" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Nombre "),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product.name,
                          expression: "product.name",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", name: "text" },
                      domProps: { value: _vm.product.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.product, "name", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.has("name")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("name")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Dirección "),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.product.direction,
                            expression: "product.direction",
                          },
                        ],
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.product,
                              "direction",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "ltr" } }, [
                          _vm._v("LTR"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "rtl" } }, [
                          _vm._v("RTL"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("direction")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("direction")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Codigo "),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product.code,
                          expression: "product.code",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", name: "text" },
                      domProps: { value: _vm.product.code },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.product, "code", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.has("code")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("code")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { staticClass: "text-dark" }, [
                      _vm._v("Directorio "),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product.directory,
                          expression: "product.directory",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", name: "text" },
                      domProps: { value: _vm.product.directory },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.product,
                            "directory",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.errors.has("directory")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("directory")),
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.product.is_default,
                          expression: "product.is_default",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        type: "checkbox",
                        name: "text",
                        id: "is_default",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.product.is_default)
                          ? _vm._i(_vm.product.is_default, null) > -1
                          : _vm.product.is_default,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.product.is_default,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.product,
                                  "is_default",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.product,
                                  "is_default",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.product, "is_default", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "text-dark",
                        attrs: { for: "is_default" },
                      },
                      [_vm._v("Marcar por defecto ")]
                    ),
                    _vm._v(" "),
                    _vm.errors.has("is_default")
                      ? _c("small", {
                          staticClass: "form-text text-danger",
                          domProps: {
                            textContent: _vm._s(_vm.errors.get("is_default")),
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.addUpdateproduct()
                    },
                  },
                },
                [_vm._v("\n        Enviar\n      ")]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-title mb-0" }, [
      _c("h3", { staticClass: "card-label mb-0 font-weight-bold text-body" }, [
        _vm._v(
          "\n                      Lista de Productos\n                    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }