var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group row" }, [
    _c("ul", { staticClass: "list-unstyled mb-0 login-forms" }, [
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: { type: "checkbox", name: "wishList", id: "appDisplay1" },
              domProps: {
                checked: _vm.display.wishList == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay1" },
              },
              [_vm._v("Wishlist")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "edit_profile",
                id: "appDisplay2",
              },
              domProps: {
                checked: _vm.display.edit_profile == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay2" },
              },
              [_vm._v("Editar Perfil")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "shipping_address",
                id: "appDisplay3",
              },
              domProps: {
                checked:
                  _vm.display.shipping_address == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay3" },
              },
              [_vm._v("Dirección de Entrega")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: { type: "checkbox", name: "my_orders", id: "appDisplay4" },
              domProps: {
                checked: _vm.display.my_orders == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay4" },
              },
              [_vm._v("Mis Ordenes")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "contact_us",
                id: "appDisplay5",
              },
              domProps: {
                checked: _vm.display.contact_us == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay5" },
              },
              [_vm._v("Contacto")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: { type: "checkbox", name: "about_us", id: "appDisplay6" },
              domProps: {
                checked: _vm.display.about_us == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay6" },
              },
              [_vm._v("Acerca de")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: { type: "checkbox", name: "news", id: "appDisplay7" },
              domProps: {
                checked: _vm.display.news == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay7" },
              },
              [_vm._v("News")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: {
                type: "checkbox",
                name: "introduction",
                id: "appDisplay8",
              },
              domProps: {
                checked: _vm.display.introduction == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay8" },
              },
              [_vm._v("Introducción")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: { type: "checkbox", name: "shareApp", id: "appDisplay9" },
              domProps: {
                checked: _vm.display.shareApp == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay9" },
              },
              [_vm._v("Compartir APP")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: { type: "checkbox", name: "rateapp", id: "appDisplay10" },
              domProps: {
                checked: _vm.display.rateapp == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay10" },
              },
              [_vm._v("Indicador App")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("li", { staticClass: "mr-2 mb-1" }, [
        _c("fieldset", [
          _c("div", { staticClass: "custom-control custom-checkbox" }, [
            _c("input", {
              staticClass: "custom-control-input",
              attrs: { type: "checkbox", name: "setting", id: "appDisplay11" },
              domProps: {
                checked: _vm.display.setting == "show" ? "checked" : "",
              },
              on: {
                change: function ($event) {
                  return _vm.check($event)
                },
              },
            }),
            _c(
              "label",
              {
                staticClass: "custom-control-label",
                attrs: { for: "appDisplay11" },
              },
              [_vm._v("Configurar")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-12" }, [
        _c("br"),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                return _vm.updateSetting()
              },
            },
          },
          [_vm._v("Enviar")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }